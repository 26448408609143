export const resources = {
    Accept: "Accept",
    AcceptAllPrograms: "Accept All Programs",
    AcceptProgram: "Accept Program",
    AcceptedBy: "Accepted By",
    AcceptedOn: "Accepted On",
    AccountManager: "Account Manager",
    AccountManager1: "Account Manager 1",
    AccountManager2: "Account Manager 2",
    AcidFluidSytemIsUsedCorrosionInhibitorChemicalRequiredWarning:
        "Acid or Acid Spearhead fluid system is programmed, a corrosion inhibitor is required.",
    Action: "Action",
    Activator: "Activator",
    ActivatorConcentration: "Activator Concentration",
    ActivatorLoading: "Activator Loadings",
    Activators: "Activators",
    ActivatorsAndBallSealers: "Activators / Ball Sealers",
    AddAdditionalItems: "Add Additional Items",
    AddAttachment: "Add Attachment",
    AddAttachmentOnlyPdf: "Add Attachment (.pdf only)",
    AddCartage: "Add Cartage",
    AddEquipment: "Add Equipment",
    AddMaterials: "Add Materials",
    AddNewRecord: "Add New Record",
    AddNewStage: "Add New Stage",
    AdditionalCartage: "Additional Cartage",
    AdditionalCartageSubtotal: "Additional Cartage Subtotal",
    AdditionalChemicals: "Additional Chemicals",
    AdditionalComments: "Additional Comments",
    AdditionalEquipment: "Additional Equipment",
    AdditionalEquipmentSubtotal: "Additional Equipment Subtotal",
    AdditionalItems: "Additional Items",
    AdditionalItemsOnNewPage: "Additional Items On New Page",
    AdditionalItemsSubtotal: "Additional Items Subtotal",
    AdditionalItemsTotal: "Additional Items Total",
    AdditionalMaterials: "Additional Materials",
    AdditionalMaterialsSubtotal: "Additional Materials Subtotal",
    AdditionalWorkmansCompensation: "Additional Workmans Compensation",
    Address1: "Address 1",
    Address2: "Address 2",
    AdvanceChemicalLoadingHaveBeenOverwrittenWarning:
        "Advance chemical loadings are overwritten and perhaps programmer unaware. Confirm and update or ignore if intended.",
    Advanced: "Advanced",
    AdvancedChemicalSchedule: "Advanced Chemical Schedule",
    AfeNumber: "AFE #",
    AllAccepted: "All Accepted",
    AllDataManuallyEnteredOnThisPageWillBeLostAreYouSureYouWantToReplaceTheLoadingsConfirmation:
        "All data manually entered on this page will be lost. Are you sure you want to replace the loadings below with the default ones?",
    AllFederalTaxes: "All Federal Taxes",
    AlsoCopyDataFromRequest: "Also copy data from Request",
    AlternativeInjectionPoint: "Alternative Injection Point",
    AnUnexpectedErrorHasOccurred: "An Unexpected Error Has Occurred.",
    Annulus: "Annulus",
    ApiNumber: "API #",
    ApplicationVersion: "Application Version",
    Apply: "Apply",
    ApplyToBaseFluid: "Apply to Base Fluid",
    ApplyToFluid: "Apply to Fluid",
    ApprovalValidationListIntroText:
        "Please review the following issues on this page:",
    Approved: "Approved",
    ApprovedBy: "Approved By",
    ApprovedByAccountManager: "Approved By (Account Manager)",
    ApprovedDate: "Approved Date",
    AreYouSureYouWantToDeleteTheProgram:
        "Are you sure you want to delete the Program",
    AreYouSureYouWantToDeleteThisRecord:
        "Are you sure you want to delete this record?",
    Area: "Area",
    AssignTo: "Assign To",
    AssignedTo: "Assigned To",
    Asterisk: "* ",
    AtLeastOneFluidSystemMustBeDefined:
        "At least one Fluid System must be defined.",
    AtLeastOnePricingSectionIsRequired:
        "At least one pricing section is required",
    AtLeastOneRequiredFieldMustBeDefinedOnTheProgram:
        "At least one {0} must be defined on the program",
    AttachmentError: "Unable to upload attachment",
    AttachmentNamesMustBeUnique:
        "Attachment names must be unique. Following attachment names are duplicated: {0}",
    Attachments: "Attachments",
    AveragePricePerStage: "Average Price Per Stage",
    BHFP: "BHFP",
    BHFPFPHH: "BHFP + FP - HH",
    BHN2SpaceFactor: "BH N2 Space Factor",
    BHReservoirPressure: "BH Reservoir Pressure",
    BHSqueezePressure: "BH Squeeze Pressure",
    BHTemp: "BH Temp",
    BHTempCalculator: "BH Temp Calculator",
    BHTempEst: "BH Temp Est",
    BackCalculate: "Back Calculate",
    BackCalculateHelpMessage:
        "Please select a cell from this column and hold control+G to trigger a back calculation",
    BackCalculateInvalidCell1: "Please select a cell in this column.",
    BackCalculateInvalidCell2: `Please select a cell in the ${0} column.`,
    BackTo: "Back to",
    BackToFlowConfigs: "Back to Flow Configurations",
    BackToJob: "Back to Job",
    BackToSchedules: "Back to Schedules",
    BallSealer: "Ball Sealer",
    BallSealers: "Ball Sealers",
    BallSize: "Ball Size",
    BaseFluid: "Base Fluid",
    BaseFluidConcentrations: "Base Fluid Concentrations",
    BaseFluidTemperature: "Base Fluid Temperature",
    BaseFluids: "Base Fluids",
    BasedOnTheCurrentConditionsBackCalculationIsNotSupportedForThisField:
        "Based on the current conditions back calculation is not supported for this field.",
    BasicInformation: "Basic Information",
    BiocideChemicalRequiredWarning:
        "Coiled Tubing is being programmed, a Biocide is required",
    BitSize: "Bit Size",
    BlenderConcentration: "Blender Conc",
    BlenderConcentrationEnd: "Blender Conc End",
    BlenderConcentrationStart: "Blender Conc Start",
    BookPrice: "Book Price",
    BothInnerAndOuterTubularMustBeSpecifiedForAnnulusAndAnnulusAndTubularFlowType:
        "Both Inner and Outer tubular must be specified for Annulus and Tubing & Annulus Pump Down types",
    Bottom: "Bottom",
    BottomHoleFracture: "Bottom Hole Fracture Pressure",
    BottomHoleFracturePressureBHFP: "Bottom Hole Fracture Pressure (BHFP)",
    BottomHoleWellLocation: "Bottom Hole Well Location",
    BottomWellLatLong: "Bottom Well Lat/Long",
    BottomWellLatitude: "Bottom Well Latitude",
    BottomWellLongitude: "Bottom Well Longitude",
    Bottoms: "Bottoms",
    BottomsAmount: "Bottoms Amount",
    BottomsUp: "Bottoms-up",
    BottomsUpVolume: "Bottoms-up Volume",
    BottomsVolume: "Bottoms Volume",
    BracketedOptional: "(Optional)",
    BreakDownOfTotalTime: "Breakdown of Total Time",
    CO2: "CO2",
    CO2Cumulative: "CO2 Cum",
    CO2Group: "CO2 (group)",
    CO2PumpPower: "CO2 Pump Power",
    CO2Rate: "CO2 Rate",
    CO2Ratio: "CO2 Ratio",
    CO2Stage: "CO2 Stage",
    CTTreatmentObjective: "CT Treatment Objective",
    CalculateAndSave: "Calculate & Save",
    CalculationSummary: "Calculation Summary",
    CalculationSummarySummaryOnNewPage: "Summary On New Page",
    CalfracRepresentative: "Calfrac Representative",
    CalfracWellServices: "Calfrac Well Services",
    CalfracWellServicesCorp: "Calfrac Well Services Corp.",
    CalfracWellServicesLtd: "Calfrac Well Services Ltd.",
    CanNotUnCheckHasSecondaryFluidSystemsFlagBecauseUsedOnChemicalLoadingScreen:
        'Can not uncheck "Has Secondary Fluid Systems" flag as chemical loading default have Fluid System Type set to Secondary',
    Cancel: "Cancel",
    Cancelled: "Cancelled",
    CannotRemoveTheFollowingCompletionTechnologiesSinceTheyAreBeingReferencedByAZoneStage:
        "Cannot remove the following Completion Technologies since they are being referenced by a Zone / Stage. {0}",
    CannotRemoveTheFollowingFlowConfigurationsSinceTheyAreBeingReferencedByAZoneStage:
        "Cannot remove the following Flow Configurations since they are being referenced by a Zone / Stage. {0}",
    CannotRemoveTheFollowingFluidsEnergyProppantsSinceTheyAreBeingReferencedByCustomPremixedChemicals:
        "Cannot remove the following program fluid systems since they are being referenced by a custom premixed chemical. {0}",
    CannotRemoveTheFollowingFluidsEnergyProppantsSinceTheyAreBeingReferencedByScheduleStagesPrimaryFluid:
        "Cannot remove the program fluid systems since they are being referenced by schedule stages primary fluid field. {0}",
    CannotRemoveTheFollowingFluidsEnergyProppantsSinceTheyAreBeingReferencedByScheduleStagesSecondaryFluid:
        "Cannot remove the program fluid systems since they are being referenced by schedule stages secondary fluid field. {0}",
    CannotRemoveTheFollowingFluidsEnergySinceTheyAreBeingReferencedByChemicalLoading:
        "Cannot remove the following program fluid systems since they are being referenced by a chemical loading. {0}",
    CannotRemoveTheFollowingFormationsSinceTheyAreBeingReferencedByAZoneStage:
        "Cannot remove the following formations since they are being referenced by a Zone / Stage. {0}",
    CannotRemoveTheFollowingItemsSinceTheyAreBeingReferencedByAFlowConfiguration:
        "Cannot remove the following Items since they are being referenced by a Flow Configuration. {0}",
    CannotRemoveTheFollowingPerforationsSinceTheyAreBeingReferencedBySchedulesX:
        "Cannot remove the following perforations since they are being referenced by schedules. {0}.",
    CannotRemoveTheFollowingProppantsSinceTheyAreBeingReferencedByActivatorLoadings:
        "Cannot remove the following proppants since they are being referenced by a activator loading. {0}",
    CannotRemoveTheFollowingProppantsSinceTheyAreBeingReferencedBySchedule:
        "Cannot remove the following proppants since they are being referenced by a schedule. {0}",
    CannotRemoveTheFollowingTreatmentModesSinceTheyAreBeingReferencedByAZoneStage:
        "Cannot remove the following Treatment Mode since they are being referenced by a Zone / Stage. {0}",
    CannotUpdateProgramWhenApproved: "Cannot update program when approved.",
    CannotUpdateProgramWhenCancelled: "Cannot update program when cancelled.",
    CannotUpdateWhenProgramUnapproved:
        "Cannot update when program is unapproved.",
    CannotUpdatedDeletedRequest: "Cannot update deleted request",
    Capacity: "Capacity",
    CarbonDioxide: "Carbon Dioxide",
    Cartage: "Cartage",
    CartageOnNewPage: "Cartage On New Page",
    CartageSubtotal: "Cartage Subtotal",
    CartageTotal: "Cartage Total",
    Casing: "Casing",
    CasingPrefix: "Casing:",
    CasingWithDescription: "Casing (OD / Weight / Grade)",
    Casings: "Casings",
    Category: "Category",
    Cement: "Cement",
    CementCumulative: "Cement Cumulative",
    CementStage: "Cement Stage",
    CementStageCumulative: "Cement Cum",
    CementTotal: "Cement Total",
    Cements: "Cements",
    ChangesYouMadeMayNotBeSaved: "Changes you made may not be saved.",
    ChemScheduleOnNewPage: "Chem Schedule On New Page",
    Chemical: "Chemical",
    ChemicalConcentrationPerVolume: "Chemical Concentration per Volume",
    ChemicalConcentrations: "Chemical Concentrations",
    ChemicalLoading: "Chemical Loadings",
    ChemicalLoadingFilterTooltip:
        "When unchecked only loadings used by the pump schedule will be displayed.",
    ChemicalLoadingMustHaveChemicalSelected:
        "Chemical field is required for chemical loading",
    ChemicalLoadingMustHaveProgramFluidSystemSelected:
        "Program Flow System field is required for chemical loading",
    ChemicalLoadingNotes: "Chemical Loading Notes",
    ChemicalLoadingStageDescriptionDefault: "Any Stages",
    ChemicalLoadings: "Chemical Loadings",
    ChemicalOnNewPage: "Chemical On New Page",
    ChemicalSchedule: "Chemical Schedule",
    ChemicalScheduleContainsLoadingOverrides:
        "Chemical Schedule contains loading overrides",
    ChemicalScheduleProppantConcentration:
        "Advanced Chemical Schedule - Proppant Concentration",
    ChemicalScheduleType: "Chemical Schedule Type",
    ChemicalTotal: "Chemical Total",
    ChemicalTotalPerPill: "Chemical Total Per Pill",
    ChemicalTotals: "Chemical Total per Schedule",
    ChemicalVolume: "Chemical Volume",
    Chemicals: "Chemicals",
    City: "City",
    CleanRate: "Clean Rate",
    CleanVolume: "Clean Volume",
    CleanVolumeCumulative: "Clean Volume Cum",
    ClearFilters: "Clear Filters",
    ClientOutputs: "Client Outputs",
    Close: "Close",
    CloseRequest: "Close Request",
    CloseRequestConfirmation: "Are you sure want to close the Request",
    ClusterBottom: "Cluster Bottom",
    ClusterDepthsForTopPerforationDepthsAreNotInConsistentOrder:
        "Cluster depths for top perforation depths are not in consistent order. All clusters should be in descending or ascending order.",
    ClusterNumber: "Cluster #",
    ClusterShots: "Cluster Shots",
    ClusterTop: "Cluster Top",
    ClusterTotal: "Cluster Total",
    Clusters: "Clusters",
    CoiledTubing: "Coiled Tubing",
    CoiledTubingAcronym: "CT",
    CoiledTubingPeriodicPills: "Coiled Tubing - Periodic Pills",
    CoiledTubingPrefix: "Coiled Tubing:",
    CoiledTubingWithDescription: "Coiled Tubing (Grade/OD/Wall Thickness)",
    CoiledTubings: "Coiled Tubings",
    Collapse: "Collapse",
    CombinedDisplacement: "Combined Displacement",
    CombinedDisplacementRate: "Combined Displacement Rate",
    CombinedDisplacementTotal: "Combined Displacement Total",
    CombinedDisplacementTotalAndRate: "Combined Displacement Total and Rate",
    Comma: ",",
    Comments: "Comments",
    CompletionTechnologies: "Completion Technologies",
    CompletionTechnology: "Completion Technology",
    Concentration: "Concentration",
    ConcentrationCalculationMode: "Concentration Calc Mode",
    ConcentrationHasBeenOverriddenClearTheCellToResetTheValueToTheDefaultOne:
        "Concentration has been overridden. Clear the cell to reset the value to the default one",
    ConfigureGrid: "Configure Grid",
    Consultant: "Consultant",
    ConsultantContact: "Consultant Contact",
    Contingency: "Contingency",
    ContingencyAmount: "Contingency Amount",
    ContingencyChemicals: "Contingency Chemicals",
    ContingencyVolume: "Contingency Volume",
    ConvertToExecutableJob: "Convert to Executable Job",
    CopiedFrom: "Copied From",
    Copy: "Copy",
    CopyDown: "Copy Down",
    CopyPricingItems: "Copy Pricing Items",
    CopyProgram: "Copy Program",
    CopySchedule: "Copy Schedule",
    Country: "Country",
    County: "County",
    CoverTitlePage: "Cover/Title Page",
    CreateJob: "Create Job",
    CreateProgram: "Create Program",
    CreateRequest: "Create Request",
    CreatedBy: "Created By",
    CreatedDate: "Created Date (MST)",
    CreatedOn: "Created On",
    CreditCheckRequired: "Credit Check Required",
    CreditDataNotAvailable: "Credit Data Not Available",
    CreditWarning: "Credit Warning",
    CriticalIdentifier: "Critical Identifier",
    CriticalIdentifiers: "Critical Identifiers",
    CtTreatmentObjectives: "CT Treatment Objective",
    CumulativeTime: "Cumulative Time",
    CurrentDatabaseUser: "Current Database User",
    CurrentServerTime: "Current Server Time",
    CurrentUserActiveDirectorySid: "Current User Active Directory SID",
    CurrentUserIsNotAccountManager: "Current user is not Account Manager 1",
    CurrentUserLocalTime: "Current User Local Time",
    CurrentUserNotFound:
        "Could not find the current user in the user database.",
    CustomAdditionalItems: "Custom Additional Items",
    CustomAdditionalItemsSubtotal: "Custom Additional Items Subtotal",
    CustomErrorsEnabled: "Custom Errors Enabled",
    CustomPremixedChemicalsMustHaveProgramFluidSystemPopulatedErrorMessage:
        "Program Fluid System field is required for custom premixed chemicals",
    CustomPremixedChemicalsMustHaveVolumePopulatedErrorMessage:
        "Volume field is required for custom premixed chemicals",
    Customer: "Customer",
    CustomerAccountManagers: "Customer / Account Managers",
    CustomerCode: "Customer Code",
    CustomerCompany: "Customer Company",
    CustomerContact: "Customer Contact",
    CustomerNotificationForm: "Customer Notification Form",
    CustomerType: "Customer Type",
    CustomerTypeIsRequiredIfCustomerCompanyOrContactPopulated:
        "{PropertyName} is a required field when Customer Company or Customer Contact populated",
    DLS: "DLS",
    Dash: "-",
    Debug: "Debug",
    DefaultErrorWindowMessage:
        "Please refresh the page or try the operation again. If the problem continues, please contact the support team.",
    DefaultOptionLabel: " ",
    DefaultTreatmentTitle: "Default Treatment Title",
    DefinedTarget: "Defined Target",
    Delete: "Delete",
    DeleteProgram: "Delete Program",
    DeleteRequestConfirmation: "Are you sure want to delete the Request",
    Density: "Density",
    Depth: "Depth",
    DepthOffset: "Depth Offset",
    DepthRangesMustBeContiguous: "Depth ranges must be contiguous",
    DepthToTopPerf: "Depth To Top Perf",
    Description: "Description",
    DesignCriteria: "Design Criteria",
    DesignResults: "Design Results",
    Details: "Details",
    Development: "Development",
    Diagnostics: "Diagnostics",
    Diameter: "Diameter",
    DijisCanadaProgramPayloadAttachmentName: "ProgramData.xls",
    DijisIntegrationIsDisabled: "DiJIS integration is disabled",
    DijisUsaProgramPayloadAttachmentName: "USProgramData.xls",
    Discount: "Discount",
    DiscountP: "Discount (%)",
    Dispatch: "Dispatch",
    DisplacementVolume: "Displ. Volume",
    DisplayCustomerCodeColumn: "Display Customer Code Column",
    DisplayNotInUse: "Display not in use",
    DisplayOnOutput: "Display on Output",
    DisplayPriceCodeColumn: "Display Price Code Column",
    District: "District",
    DivisionalEngineer: "Divisional Engineer",
    Done: "Done",
    Downhole: "Downhole",
    DownholeConcentration: "DH Conc",
    DownholeConcentrationEnd: "DH Conc End",
    DownholeConcentrationStart: "DH Conc Start",
    DownholeCondition: "Downhole Condition",
    DownholeConditions: "Downhole Conditions",
    DownholeConditionsGroup: "Downhole Conditions (group)",
    DownholeFoamQuality: "Downhole Foam Quality",
    DownholeItem: "Downhole Item",
    DownholeItems: "Downhole Items",
    DownholeN2Rate: "DH N2 Rate",
    DownholeRate: "Downhole Rate",
    DownholeTotal: "Downhole Total",
    DownholeVolume: "Downhole Volume",
    DownloadAttachmentTemplate: "Download Attachment Template",
    DownloadClientOutputs: "Download Client Outputs",
    DownloadImportIntervalsTemplate: "Download Import Intervals Template",
    DownloadWellboreDiagramTemplate: "Download Wellbore Diagram Template",
    DueDate: "Due Date",
    Edit: "Edit",
    EditFilter: "Edit Filter",
    EditNotes: "Edit Notes",
    EditPerforationClusters: "Edit Perforation Clusters",
    EditProgram: "Edit Program",
    EditSchedule: "Edit Schedule",
    Email: "Email",
    EmailAllowPattern: "Email Allow Pattern",
    EmailCc: "Email CC",
    EmailFromAddress: "Email From Address",
    EmailPickupDirectory: "Email Pickup Directory",
    EmailSentToOperations: "Email Sent to Operations. It will arrive shortly.",
    EmailTo: "Email To",
    EmailToFieldMustHaveAtLeastOneEmail:
        "Email To field must have at least one value",
    EndDepth: "End Depth",
    EndDepthMustBeGreaterThanStartDepth:
        "End Depth must be greater than Start Depth",
    EnergizedFluids: "Energized Fluids",
    EnergyTypes: "Energy Types",
    Entity: "Entity",
    EqualitySymbol: "=",
    Equipment: "Equipment",
    EquipmentFlushing: "Equipment Flushing",
    EquipmentMileage: "Equipment Mileage",
    EquipmentMileageMI: "Equipment Mileage (mi)",
    EquipmentSection: "Equipment Section",
    EquipmentSubtotal: "Equipment Subtotal",
    EquipmentTotal: "Equipment Total",
    Error: "Error",
    ErrorAPI: "An error has occurred while processing your request.",
    ErrorEmailToAddresses: "Error Email To Addresses",
    ErrorFileTooLarge: "Filesize is too large for upload.",
    ErrorOccuredWhileSendingActionNotifiation:
        "An Unexpected Error Has Occurred while sending action notification.",
    ErrorOccuredWhileSendingRequestNotifiation:
        "An Unexpected Error Has Occurred while sending request notification.",
    ErrorOccurredWhileSendingTheProgramToDijis:
        "An Error occurred while sending the program to DiJIS: {0} Please contact your system administrator for assistance",
    ErrorRequestTooLarge: "Payload is too large for request.",
    EstimatedDepth: "Estimated Depth",
    EstimatedDepthOnExecutableJobWarning:
        "Estimate depths are currently programmed, please update info if actual depths are available.",
    EstimatedGrandTotal: "Estimated Grand Total",
    EstimatedJobStartDate: "Estimated Job Start Date",
    EstimatedTime: "Estimated Time (hh:mm:ss)",
    ExampleAbbreviation: "eg.",
    ExcelWorkbookReadError:
        "Could not read the Excel workbook. Please make sure the file is valid.",
    ExecuteActionWarning:
        "Executing this action will send notification email to the following recipients:",
    Executed: "Executed",
    ExportIntervals: "Export Intervals",
    ExternalInhibitors: "External Inhibitors",
    ExtraPremixedChemicalsMustHaveTotalPopulatedErrorMessage:
        "Total is a required field for premixed chemicals of extra type",
    FederalWell: "Is Federal Well",
    Field: "Field",
    FieldToBackCalculate: "Field To Back Calculate",
    Fields: "Fields",
    File: "File",
    FileName: "File Name",
    FileUploadLimitations:
        "* Please attach PDF files using the Wellbore Diagram Template only.",
    FilterName: "Filter Name",
    Filters: "Filters",
    Final: "Final",
    FinalPrice: "Final Price",
    FinalTotal: "Final Total",
    FirstFlowConfiguratioknItemMustHaveAStartDepthOfZeero:
        "First Flow Configuration item must have a Start Depth of zero",
    Flagged: "Flagged",
    FloatCollar: "Float Collar",
    FloatShoe: "Float Shoe",
    FlowConfigTotalVolume: "Flow Config Total Volume",
    FlowConfigVolume: "Flow Config Volume",
    FlowConfiguration: "Flow Config",
    FlowConfigurationDescription: "Flow Configuration Description",
    FlowConfigurationItems: "Flow Configuration Items",
    FlowConfigurations: "Flow Configurations",
    Fluid: "Fluid",
    FluidPumpPower: "Fluid Pump Power",
    FluidRate: "Fluid Rate",
    FluidRates: "Fluid (Rates)",
    FluidSystem: "Fluid System",
    FluidSystemBaseFluid: "Fluid System / Base Fluid",
    FluidSystemType: "Fluid System Type",
    FluidSystemTypeValidationOnChemicalLoading:
        "Secondary fluid system type can only be selected when Program is flagged to have Secondary Fluid Systems",
    FluidSystems: "Fluid Systems",
    FluidSystemsZReferencedByScheduleXStageY:
        "Fluid Systems {2} referenced by Schedule # {0} ( Stage # {1})",
    FluidTotal: "Fluid Total",
    FluidTotalAndRate: "Fluid Total and Rate",
    FluidType: "Fluid Type",
    Fluids: "Fluids",
    FluidsAndCements: "Fluids & Cements",
    FluidsAndChemicals: "Fluids & Chemicals",
    FluidsEnergyAndProppants: "Fluids, Energy & Proppants",
    Flush: "Flush",
    FlushVolume: "Flush Volume",
    FoamQuality: "Quality",
    FollowingNotificationEmailRecipientsDoNotHaveValidEmailAddress:
        "Following email recipients don't have email address: {0}",
    FollowingPerforationClustersDontHaveUniquePerforationClusterNumberX:
        "Following Perforation Clusters don't have unique perforation cluster number: {0}",
    FollowingPerforationClustersHaveBottomDepthSmallerThanTopDepthX:
        "Following Perforation Clusters have Bottom Depth smaller than Top Depth: {0}",
    FollowingPerforationsHaveBottomDepthSmallerThanTopDepthX:
        "Following Perforations have Bottom Depth smaller than Top Depth: {0}",
    FollowingPerforationsHaveTopDepthOutsideOfTheirFlowConfigurationDepthRangeX:
        "Following Perforations have Top Depth outside of their Flow Configuration depth range: {0}",
    ForServiceCall: "For Service Call",
    Formation: "Formation",
    FormationAccess: "Formation Access",
    FormationAndDefinedTargetMustBeUnique:
        "Formations and defined target must be unique.",
    FormationField: "Formation/Field",
    Formations: "Formations",
    Formula: "Formula",
    FracGradient: "Frac Gradient",
    FracGradientxTVD: "Frac Gradient x TVD",
    FracPressure: "Frac Pressure",
    FractureTreatment: "Fracture Treatment",
    Frequency: "Frequency",
    FrictionGradient: "Friction Gradient",
    FrictionGradientxDepth: "Friction Gradient x Depth",
    FuelDelivery: "Fuel Delivery",
    GenerateClientOutputs: "Generate Client Outputs",
    GeneratingPDF: "Generating PDF...",
    GeneratingPDFError:
        "There was an error generating a PDF report for this program:",
    GenericSubtotalDescriptionOne: "Generic Subtotal Description 1",
    GenericSubtotalDescriptionThree: "Generic Subtotal Description 3",
    GenericSubtotalDescriptionTwo: "Generic Subtotal Description 2",
    GenericSubtotalOne: "Generic Subtotal 1",
    GenericSubtotalThree: "Generic Subtotal 3",
    GenericSubtotalTwo: "Generic Subtotal 2",
    GoBackHome: "Go back home",
    Grade: "Grade",
    GrandTotal: "Grand Total",
    GrandTotalAfterDiscount: "Grand Total After Discount",
    GrandTotalAveragePerStage: "Total Average Per Stage",
    GrandTotalBeforeDiscount: "Grand Total Before Discount",
    GrandTotalFirstStage: "Grand Total - First Stage",
    GrandTotalOtherStages: "Grand Total - Other Stages",
    GroupMaterialsByFluidSystem: "Group Materials By Fluid System",
    H2S: "H2S",
    HasSecondaryFluidSystem: "Secondary Fluid Systems",
    HeavyEquipmentTotal: "Heavy Equipment Total",
    HelperTextCreateProgram:
        'To get started, fill out the fields below and hit Ctrl-S or select "Save" (upper right corner of page) to create a program.',
    HelperTextCreateRequest:
        'To get started, fill out the fields below and hit Ctrl-S or select "Next" (upper right corner of page) to create a request.',
    Home: "Home",
    Hybrid: "Hybrid",
    HydrostaticHead: "Hydrostatic Head",
    HydrostaticHeadHH: "Hydrostatic Head (HH)",
    ID: "ID",
    IfTheProblemPersistsPleaseContactTheSupportTeam:
        "If the problem persists please contact the support team.",
    Ignore: "Ignore",
    ImportIntervals: "Import Intervals",
    InApproval: "In Approval",
    InDesign: "In Design",
    InReview: "In Review",
    InUse: "In Use",
    IndianWell: "Is Indian Well",
    InheritedAttachments: "Inherited Attachments",
    InjectionPoints: "Injection Points",
    InjectionPointsAndIntrinsikTable: "Injection Points & Intrinsik Table",
    InnerTubular: "Inner Tubular",
    InsertAdditionalPages: "Insert Additional Pages",
    InsertNewRecordAbove: "Insert Above",
    IntYield: "Int. Yield",
    Intervals: "Intervals",
    IntrinsikDesignation: "Intrinsik Designation",
    InvalidDate: "Invalid Date",
    InvalidFile: "One of the files you've uploaded is not valid.",
    InvalidRoleError: "Incorrect User Role",
    InvalidRoleErrorMessage:
        "You must be in the 'Jet User' group to access this resources. Please contact your system administrator.",
    IsARequiredField: "{PropertyName} is a required field",
    IsARequiredFieldFormatable: "{0} is a required field",
    IsAdvanced: "Is Advanced",
    IsCtanAndAcidOrAcidSpearheadFluidSystemWarning:
        "Coiled Tubing potentially operating with Acid. Please ensure corrosion inhibitor loading for CT are accurate. Confirm if a 1.5 multiplier may be required on specific chemicals.",
    IsRushed: "Is Rushed",
    ItemsNotIncludedInAboveEstimate: "Items not included in above estimate:",
    ItemsOnPricePageMayNotBeenDiscountedWarning:
        "Items on price page may not be discounted, please confirm",
    Jet: "JET",
    JetConnectionString: "Jet Connection String",
    JetVersionApprovedIn: "JET Version Approved In",
    Job: "Job",
    JobExecuted: "Job Executed",
    JobNumber: "Job ID",
    JobParameters: "Job Parameters",
    Jobs: "Jobs",
    KickOffPoint: "Kick Off Point",
    KickOffPointBottomsUpVolume: "KOP Bottoms-up",
    LabReportNumber: "Lab Report Number",
    LastSentToOperations: "Sent to Operations Date",
    LatLong: "Lat/Long",
    Latitude: "Latitude",
    Length: "Length",
    Liner: "Liner",
    LinerHanger: "Liner Hanger",
    LinerPrefix: "Liner:",
    LinerWithDescription: "Liner (OD/Weight/Grade)",
    Liners: "Liners",
    LoadDefaults: "Load Defaults",
    LoadDefaultsWarning:
        "All data manually entered on this page will be lost. Are you sure you want to replace the loadings below with the default ones?",
    Loading: "Loading",
    Location: "Location",
    LogErrorMessage: "Log Error Message",
    LogWarningMessage: "Log Warning Message",
    Longitude: "Longitude",
    Manifold: "Manifold",
    MaterialMileage: "Material Mileage",
    MaterialMileageMi: "Material Mileage (mi)",
    MaterialPricingQuantityHasBeenOverridenWarning:
        "Quantities on the price page for materials have been overwritten. Note that programmed quantities may differ than price quantities and will not automatically calculate.",
    Materials: "Materials",
    MaterialsOnNewPage: "Materials On New Page",
    MaterialsSection: "Materials Section",
    MaterialsSubtotal: "Materials Subtotal",
    MaterialsTotal: "Materials Total",
    MaxCO2Rate: "Max CO2 Rate",
    MaxCleanRate: "Max Clean Rate",
    MaxCombinedDisplacementRate: "Max Combined Displacement Rate",
    MaxCombinedDownholeRate: "Max Combined Downhole Rate",
    MaxDepth: "Max Depth",
    MaxDepthCoil: "Max Coil Depth",
    MaxDownholeRate: "Max Downhole Rate",
    MaxFileSize: "Files must be smaller than 1GB.",
    MaxFluidRate: "Max Clean/Fluid Rate",
    MaxFracGradient: "Max Frac Gradient",
    MaxFrictionGradient: "Max Friction Gradient",
    MaxN2Rate: "Max N2 Rate",
    MaxRate: "Max Rate",
    MaxReqCO2Power: "Max Req CO2 Power",
    MaxReqPumpPower: "Max Req Pump Power",
    MaxRequiredPumpPower: "Max Required Pump Power",
    MaxSlurryRate: "Max Slurry Rate",
    MaxTreatingPressure: "Max Treating Pressure",
    MaxTreatingRate: "Max Treating Rate",
    MaxWallThickness: "Max Wall Thickness",
    MaximumSandDeliveryAtBelenderMustNoExceed10000CanadaWarning:
        "Maximum sand delivery at blender must not exceed 10,000kg/min.",
    MaximumSandDeliveryAtBelenderMustNoExceed10000UsaWarning:
        "Warning surface pressure are above 68.9 Mpa (10,000 psi), please discuss with operations before approving.",
    Message: "Message",
    Mileage: "Mileage",
    MileageMustHaveAUnitOfMeasure: "Mileage must have a unit of measure.",
    MinimumBlenderCleanFluidRateCanNotBeLowerWarning:
        "Minimum blender clean fluid rate cannot go lower than 0.7m3/mim.",
    Misc1: "Misc 1",
    Misc1Title: "Miscellaneous 1 Title",
    Misc2: "Misc 2",
    Misc2Title: "Miscellaneous 2 Title",
    MiscColumnNames: "Miscellaneous Column Names",
    Multiplier: "Multiplier",
    N2: "N2",
    N2Cumulative: "N2 Cum",
    N2Group: "N2 (group)",
    N2Rate: "N2 Rate",
    N2Ratio: "N2 Ratio",
    N2Stage: "N2 Stage",
    N2Total: "N2 Total",
    N2TotalAndRate: "N2 Total and Rate",
    NTS: "NTS",
    Name: "Name",
    NegativeIndicatesUnderFlushWhilePositiveIndicatesOverFlush:
        "Negative flush values indicate under flush while positive ones indicate over flush",
    NetDepth: "Net Depth",
    NetDepthTotal: "Net Depth Total",
    NetMeter: "Net Meter",
    NetMeterTotal: "Net Meter Total",
    NewRequest: "New Request",
    Next: "Next",
    NextTasks: "Next Task(s)",
    NinetyDegreeHeel: "90° heel",
    Nitrogen: "Nitrogen",
    No: "No",
    NoAreaIsSelected: "No Area is selected",
    NoFieldsFound: "No Fields Found",
    NoFieldsRequiredForCalculation: "No fields required for this calculation",
    NoFinanceItemFoundFor: "No Finance Item found for {0}",
    NoRecords: "No records found",
    NoRecordsInUse: "No in use records found",
    NoRecordsYetAddOneAbove: "No records yet, add one above.",
    NoRiskAssessmentOptionsApply: "None of the above risks apply",
    NoSchedulesFoundForThisProgram: "No Schedules found for this program",
    NoTargetColumnSelected: "No Target Column Selected",
    Note: "Note",
    Notes: "Notes",
    NotesTitle: "NOTES:",
    NotificationRecipients: "Notification Recipients",
    NotificationSent: "Notification Sent",
    Number: "#",
    NumberBridgePlugs: "# of Bridge Plugs",
    NumberFracPorts: "# of Seats",
    NumberOfIgnoredWarnings: "# of Warnings",
    NumberOfIntervals: "# of Intervals",
    NumberOfWellsOnPad: "Total # of Wells on Pad",
    OD: "OD",
    Offset: "Offset",
    Ok: "Ok",
    OnBehalfOf: "On Behalf Of",
    OnePerforationRequired:
        "At least 1 Perforation is required for Nitrogen Space Factor calculation",
    OneThousandMBottomsUpVolume: "1000m Bottoms-up",
    OpenHole: "Open Hole",
    OpenHolePrefix: "Open Hole:",
    OpenHoleWithDescription: "Open Hole (ID)",
    OpenHoles: "Open Holes",
    OpenTasks: "Open Tasks",
    OperationNotificationsAttachments: "Operations Notifications Attachments",
    OperationType: "Operation Type",
    Operator: "Operator",
    OperatorAccountManagers: "Operator / Account Managers",
    OperatorContact: "Operator Contact",
    OperatorNumber: "Operator Number",
    Optional: "Optional",
    OriginalProgram: "Original Program",
    OriginalProgramIsARequiredFieldWhenRequestTypeIsRevision:
        "{PropertyName} is a required field when for program request type is Revision, Price Revision, Pump Schedule Tool",
    OriginalProgramMustBeApproved:
        'Selected Original Program "{0}" must be approved',
    Other: "Other",
    OuterTubular: "Outer Tubular",
    OuterTubularDiameterMustBeGreaterThanInnerTubularDiameter:
        "Outer Tubular Diameter must be greater than Inner Tubular Diameter",
    OutsideDiameter: "Outside Diameter",
    Overflush: "Overflush",
    OverridenQuantity: "Overriden Quantity",
    PBTD: "PBTD",
    Pad: "Pad",
    PadLatLong: "Pad Lat/Long",
    PadLatitude: "Pad Latitude",
    PadLongitude: "Pad Longitude",
    PadName: "Pad Name",
    PageNotFound: "Page Not Found",
    PageXofY: "Page $p of $P",
    Percentage: "Percentage",
    PerfBottomsUpVolume: "Perf Bottoms-Up Volume",
    PerfDisplacementVolume: "Perf Displacement Volume",
    PerfVolumeOne: "Perf Volume One",
    PerfVolumeTwo: "Perf Volume Two",
    Perforation: "Perforation",
    PerforationBottom: "Perforation Bottom",
    PerforationClusters: "Perforation Clusters",
    PerforationDepthForTopMAreNotInDescendingOrder:
        "Perforation depths for Tops (m) are not in descending order",
    PerforationDiameter: "Perforation Diameter",
    PerforationIntervalSeat: "Perforation Interval Seat",
    PerforationPlugDepth: "Perforation Plug Depth",
    PerforationTop: "Perforation Top",
    PerforationXPerforationClusterY:
        "Perforation #: {0} Perforation Cluster #: {1}",
    PerforationZoneNumberMustBeUniqueFollowingZoneNumbersAreDuplicatedX:
        "Perforation zone number must be unique. Following zone numbers are duplicated: {0}",
    Perforations: "Perforations",
    PeriodicPills: "Periodic Pills",
    Permeability: "Permeability",
    PermitCosts: "Permit Costs",
    PipeArea: "Pipe Area",
    PleaseSaveYourCurrentChangesPriorToRunningBackCalculations:
        "Please save your current changes prior to running back calculations.",
    PleaseSaveYourCurrentChangesPriorToRunningFormulaCalculations:
        "Please save your current changes prior to running formula calculations.",
    PleaseSelectASingleCell: "Please select a single cell",
    PleaseSelectTheProgramFirst: "Please select the program first",
    PleaseSelectTheProgramSchedule: "Please select the program schedule",
    PlugDepth: "Plug Depth",
    PoreGradient: "Pore Gradient",
    PorePressure: "Pore Pressure",
    Porosity: "Porosity",
    PostalCode: "Postal Code",
    Premix: "Premix",
    Premixed: "Premixed",
    PremixedChemical: "Premixed Chemical",
    PremixedChemicals: "Premixed Chemicals",
    PremixedChemicalsCustom: "Custom Premix",
    PremixedChemicalsExtra: "Extra",
    PremixedChemicalsMustHaveConcentrationPopulatedErrorMessage:
        "Concentration field is required for premixed chemicals",
    PremixedChemicalsStandard: "Standard",
    PremixedItems: "Premix & Additional Items",
    PreparedBy: "Prepared By",
    Pressure: "Pressure",
    PressureTest: "Pressure Test",
    PressureTestItems: "Pressure Test Items",
    PreviousEndDepth: "Previous End Depth",
    PreviousScheduleEndDepth: "Previous Schedule End Depth",
    PreviousScheduleStartDepth: "Previous Schedule Start Depth",
    PreviousScheduleVolume: "Previous Schedule Volume",
    PreviousSchedulesVolumeSum: "Previous Schedules Volume Sum",
    PreviousStartDepth: "Previous Start Depth",
    PriceCode: "Price Code",
    PricePage: "Price Page",
    PriceRevisionRequestProgramTypeIsOnlyAvailableForFracingServiceLine:
        "Price Revision request program type is only available for Fracturing Service Line",
    PriceWarning: "Price Warning",
    Pricing: "Pricing",
    PricingMayBeOutdatedWarning:
        "Pricing may be outdated (> 2 months). If required' click the [Update Pricing & Save] button on the Pricing page and review pricing as new changes may occur",
    PricingNotice1:
        "All pricing information contained herein is confidential and proprietary to Calfrac Well Services (“Calfrac”) and is to be held in strict confidence and shall",
    PricingNotice2:
        " not be released to any person without the written consent of Calfrac.",
    PricingNotice3:
        "These prices are valid only when Calfrac Well Services materials and/or equipment are available to perform the operations required. Services undertaken by Calfrac Well Services at the request of the Customer are subject to the terms and conditions in Calfrac's current price book which terms and conditions the Customer acknowledges having received and accepted.",
    PricingSetup: "Pricing Setup",
    Primary: "Primary",
    PrimaryChemicalSchedule: "Primary Chemical Schedule",
    PrimaryEngineerContact: "Primary Engineer Contact",
    PrimaryInjectionPoint: "Primary Injection Point",
    PrimaryOperator: "Primary Operator",
    Print: "Print",
    Procedures: "Procedures",
    Program: "Program",
    ProgramApproval: "Approval",
    ProgramApprovedBy: "JET Approved By",
    ProgramApprovedDate: "JET Approved Date",
    ProgramApprovedFlag: "Mark Program as Approved",
    ProgramAttachments: "Program Attachments",
    ProgramChemicalLoading: "Program Chemical Default Loading",
    ProgramCopyingIntoMustBeActive: "Program copying into must be active",
    ProgramCustomName: "Program Custom Name",
    ProgramData: "Program Data",
    ProgramDueDate: "Program Due Date",
    ProgramFluidSystem: "Program Fluid System",
    ProgramHasToBeApproveAndBitToBeAbleToConvertToExecutable:
        "Program has to be approved and have Bid program type to be convertible to executable program type",
    ProgramHasToBeExecutableAndApprovedToBeSendToDijis:
        "Program has to be marked as executable job and approved to be send to DiJIS.",
    ProgramIndex: "Program Index",
    ProgramIsNoLongerActive: "Program {0} is no longer active",
    ProgramLogApprovedBy: "Program Log Approved By",
    ProgramLogApprovedDate: "Program Log Approved Date (MST)",
    ProgramMustHaveAtLeastOneAttachment:
        "Please attach the finalized PDF program. Requires at least one attachment, within  Operations Notifications Attachments.",
    ProgramMustHaveInDesignStatusToCopyInto:
        "Program must have In Design status to copy into",
    ProgramNotFoundById: "Program with id = {0} not found.",
    ProgramNumber: "Program Number",
    ProgramNumberAndRevision: "Program Number - Revision",
    ProgramReviewedDate: "Program Reviewed Date",
    ProgramReviewedFlag: "Mark Program as Reviewed",
    ProgramThatIsBeingCopiedMustBeActive:
        "Program that is being copied must be active",
    ProgramToCopyFrom: "Program To Copy From",
    ProgramType: "Program Type",
    ProgramTypeDescription: "Program Type Description",
    ProgramTypes: "Program Types",
    ProgramWorkflowStatus: "Program Workflow Status",
    ProgramWorkflowStatusShouldBeApprovedToPerformThisOperation:
        "Program workflow status should be approved to perform this operation.",
    ProgramWorkflowStatusShouldBeCancelledToPerformThisOperation:
        "Program workflow status should be cancelled to perform this operation.",
    ProgramWorkflowStatusShouldBeRevisedToPerformThisOperation:
        "Program workflow status should be revised to perform this operation.",
    ProgrammedIntervalDepthAreEstimatedWarning:
        "Caution: Programmed interval depths are estimated.  Please confirm before starting treatment.",
    Programmer: "Programmer",
    ProgrammerIsAlsoReviewerWarning: "Programmer is also the reviewer",
    ProgrammerIsAlsoTheAccountManagerWarning:
        "Programmer is also the Account Manager",
    ProgrammerIsApprovingOnBehalfOfAccountManagerWarning:
        "Programmer is approving on behalf of Account Manager",
    Programs: "Programs",
    ProjectDescription: "Project Description",
    ProjectType: "Project Type",
    Proppant: "Proppant",
    ProppantCumulative: "Proppant Cum",
    ProppantGroup: "Proppant (group)",
    ProppantStage: "Proppant Stage",
    ProppantTotal: "Proppant Total",
    Proppants: "Proppants",
    ProppantsAndItems: "Proppants",
    ProppantsZReferencedByScheduleXStageY:
        "Proppants {2} referenced by Schedule # {0} (Stage # {1})",
    Province: "Province",
    ProvinceState: "Province/State",
    PumpDown: "Pump Down",
    PumpType: "Pump Type",
    PumpingFrictionPressure: "Pumping Friction Pressure",
    PumpingFrictionPressureFP: "Pumping Friction Pressure (FP)",
    PumpsTotal: "Pumps Total",
    Quality: "Quality",
    Quantity: "Quantity",
    QuantityHasBeenOverriddenClearTheCellToResetTheValueToTheDefaultOne:
        "Quantity has been overridden. Clear the cell to reset the value to the default one",
    QuantityUnit: "Quantity Unit",
    QueuedForReview: "Queued For Review",
    RaiseAjaxForbiddenError: "Raise Ajax Forbidden Error",
    RaiseAjaxGeneralError: "Raise Ajax General Error",
    RaiseAjaxNotFoundError: "Raise Ajax Not Found Error",
    RaiseForbiddenError: "Raise Forbidden Error",
    RaiseGeneralError: "Raise General Error",
    RaiseNotFoundError: "Raise Not Found Error",
    RaiseStandardFileUploadGeneralException:
        "Raise Standard File Upload General Exception",
    Rate: "Rate",
    RateAll: "Rate (group)",
    RateAndTime: "Rate and Time",
    Rates: "Rates",
    RatioPercent: "Ratio (%)",
    ReReviseProgram: "Re-Revise Program",
    ReSendNotification: "Re-Send Notification",
    RecommendedActions: "Recommended Actions",
    RecoveryOfProvincialStateTaxes: "Recovery of Provincial/State Taxes",
    Reload: "Reload",
    ReloadSite: "Reload Site?",
    ReopenRequest: "Reopen Request",
    ReopenRequestConfirmation: "Are you sure want to re-open the Request",
    Repeated: "Repeated",
    Request: "Request",
    RequestAttachments: "Program Requirements Attachments",
    RequestComments: "Program Requirements Comments",
    RequestMustHaveACommentPopulatedOrAttachementAdded:
        "Request must have comment or one attachment",
    RequestMustHaveAtLeastOneProgramDefined:
        "Request must have at least one program defined",
    RequestNumber: "Request Number",
    RequestProgram: "Request Program",
    RequestProgramHasBeenAcceptedAlready:
        "Request program {0} has been accepted already by {1}",
    RequestProgramMustBeAssignedToAtLeastOneAssignee:
        "Request Program must be assigned to at least one person",
    RequestShouldBeClosedToPerformThisOperation:
        "Request should have Closed status to perform this action",
    RequestShouldBeSubmittedToPerformThisAction:
        "Request should have Submitted status to perform this action",
    RequestShouldHaveDraftStatusToPerformThisAction:
        "Request should have Draft status to perform this action",
    RequestStatus: "Request Status",
    RequestType: "Request Type",
    Requests: "Requests",
    RequiredPumpPower: "Required Pump Power",
    ResendToOperations: "Resend Program to Operations",
    ReservoirProperties: "Reservoir",
    ReservoirTVD: "Reservoir TVD",
    ReviewedBy: "Reviewed By",
    ReviewedBy1: "Reviewed By 1",
    ReviewedBy2: "Reviewed By 2",
    ReviewedByProgrammer: "Reviewed By Programmer",
    ReviewedByTechServiceEngineering:
        "Reviewed By (Tech Service / Engineering)",
    Reviewer: "Reviewer",
    ReviewerIsAlsoAccountManager: "Reviewer is also Account Manager",
    ReviewerIsApprovingOnBehalfOfAccountManagerWarning:
        "Reviewer is approving on behalf of Account Manager",
    ReviseProgram: "Revise Program",
    Revised: "Revised",
    Revision: "Revision",
    RevisionComment: "Revision Comment",
    RevisionCommentFieldShouldBeFilledOut:
        "Changes made in this revision will need to be identified in the Revision Comment Box.  This information will be communicated in all notifications",
    RevisionComments: "Revision Comments",
    RiskAssessment: "Risk Assessment",
    RiskAssessmentChecklist: "Risk Assessment Review Checklist",
    RiskAssessmentCommentsMustBeFilledOut:
        "Risk Assessment comments must be filled out if a critical identifier is selected",
    RiskAssessmentMustBePerformed: "Risk Assessment review must be performed",
    RiskAssessmentMustHaveOneCiOrNone:
        "Risk Assessment must have one or more critical identifiers selected or none apply selected",
    RiskAssessmentReview: "Risk Assessment Reviewed By (Programmer)",
    RiskAssessmentReviewComments: "Risk Assessment Review Comments",
    RiskAssessmentWasPerformed: "Risk Assessment Review was performed",
    RunMultiplier: "Run Multiplier",
    Rush: "RUSH",
    SPF: "SPF",
    SPM: "SPM",
    SPPxRateConvFactor: "(SPP x Rate) / Conv Factor",
    SafetyService: "Safety Service",
    SameEndDepth: "Same End Depth",
    SameStartDepth: "Same Start Depth",
    SampleApiFormat: "e.g. 05-123-41505",
    SampleDlsFormat: "e.g. 102/16-01-065-04W6/02",
    SampleNtsFormat: "e.g. 200/b-099-I/094-G-01/00",
    SamplePlssFormat: "e.g. S8, T1N, R65W",
    Save: "Save",
    SaveAndDownload: "Save & Download",
    SaveAndGeneratePDF: "Save & Generate PDF",
    SaveAndViewPdf: "Save & View PDF",
    Schedule: "Schedule",
    ScheduleName: "Schedule Name",
    ScheduleNumber: "Schedule #",
    ScheduleNumbersMustBeUnique: "Schedule numbers must be unique.",
    ScheduleOnNewPage: "Schedule On New Page",
    ScheduleStageWithProppantAndNoBlenderConcentrationWarning:
        "Issue found on schedule.  Stage Description indicates proppant and either proppant is not selected or missing  blender concentration.",
    Schedules: "Schedules",
    SeatSize: "Seat Size",
    SecondaryAdvancedChemicalSchedule: "Secondary Advanced Chemical Schedule",
    SecondaryChemicalLoadingHaveBeenOverwrittenWarning:
        "Secondary chemical loadings are overwritten and perhaps programmer unaware. Confirm and update or ignore if intended.",
    SecondaryChemicalSchedule: "Secondary Chemical Schedule",
    SecondaryFluidSystem: "Secondary Fluid System",
    SecondaryFluidSystemGroup: "Secondary Fluid System (group)",
    SecondaryFluidSystemRate: "2FS Rate",
    SecondaryFluidSystemRatio: "2FS Ratio",
    SecondaryFluidSystemStage: "2FS Stage",
    SecondaryFluidSystemStageCumulative: "2FS Stage Cum",
    SelectAFile: "Select a file...",
    SelectProgram: "Select Program",
    SelectProgramCopyScheduleInstructions:
        "First, Select Program to copy schedule from (Filtering by country and service line already)",
    SelectProgramToCopyFrom: "Please select program to copy from",
    SelectProgramToCopyPricingItemsInstructions:
        "First, Select Program to copy pricing items from (Filtering by country and service line already)",
    SelectSchedule: "Select Schedule",
    SelectedArea: "Selected Area",
    SelectedAreaHasNoMatchFor: "Selected Area has no match for {0}",
    SelectedOriginalProgramServiceLineDoesnMatchRequestProgramServiceLine:
        'Selected Original Program\'s service line "{0} - {1}" doesn\'t match request program\'s service line "{2}"',
    SendCcEmail: "Send Email With CC address",
    SendErrorEmail: "Send Error Email",
    SentToOperations: "Sent To Operations",
    ServiceLine: "Service Line",
    ServiceLines: "Service Lines",
    Settings: "Settings",
    Shots: "Shots",
    ShowAdditionalChemicals: "Show Additional Chemicals",
    ShowApiLabel: "Show API Q2 Label",
    ShowAveragePricePerStage: "Show Average Price Per Stage",
    ShowBitSize: "Bit Size",
    ShowCalculationSummary: "Show Calculation Summary",
    ShowChemicals: "Show Chemicals",
    ShowCustomerCode: "Show Customer Code",
    ShowDesignResultsPages: "Show Design Results Pages",
    ShowGroupedMaterialsSubtotal: "Show Grouped Materials Subtotals",
    ShowIntervalPages: "Show Interval Pages",
    ShowMaxRequiredPumpPower: "Max Required Pump Power",
    ShowPeriodicPills: "Show Periodic Pills",
    ShowPremixedChemicals: "Show Premixed Chemicals",
    ShowPressureTestTable: "Show Pressure Test Table",
    ShowPriceCode: "Show Price Code",
    ShowPricePageEquipmentGrouping: "Show Grouped Equipment Subtotals",
    ShowPricingPages: "Show Pricing Pages",
    ShowProcedurePages: "Show Procedure Pages",
    ShowProgramType: "Show program Type",
    ShowSchedulePages: "Show Schedule Pages",
    ShowSecondaryChemicalSchedule: "Show Secondary Chemical Schedule",
    ShowWellConfigPages: "Show Well Configuration Pages",
    SimulationNumber: "Simulation Number",
    SimulationPressure: "Simulation Pressure",
    SlurryRate: "Slurry Rate",
    SlurryVolume: "Slurry Volume",
    SolveAllPrograms: "Solve All Programs",
    SorryWeCouldntFindPage:
        "Sorry, we couldn't find the page you're looking for.",
    Space: " ",
    SpaceFactor: "S.F.",
    SpecificGravity: "S.G.",
    SpecificGravityxGravityConstxTVD: "Specific Gravity x Gravity Const. x TVD",
    Speed: "Speed",
    SqueezeGradient: "Squeeze Gradient",
    Stage: "Stage",
    StageDescription: "Stage Description",
    StageNumber: "Stage #",
    StageNumbersMustBeUnique: "Stage numbers must be unique",
    StageTime: "Stage Time",
    StageTimeWithUnitSymbol: "Stage Time (hh:mm:ss)",
    StageType: "Stage Type",
    StageTypeTime: "Time (hh:mm:ss)",
    Stages: "Stages",
    StagesScheduleNotes: "Stages / Schedule Notes",
    Standard: "Standard",
    StandardChemicalSchedule: "Standard Chemical Schedule",
    StandardChemicalScheduleOnNewPage: "Standard Chemical Schedule On New Page",
    StandardChemicalScheduleValidationMessage:
        "Standard Chemical Schedule is not allowed. Please remove overwritten loadings in Advance Chem Schedule",
    StandardPrimaryChemicalSchedule: "Standard Primary Chemical Schedule",
    StandardSecondaryChemicalSchedule: "Standard Secondary Chemical Schedule",
    StandardSecondaryChemicalScheduleValidationMessage:
        "Standard Chemical Schedule is not allowed. Please remove overwritten loadings in Secondary Chem Schedule",
    StartDepth: "Start Depth",
    StartDepthEndDepthAndSpeed: "Start Depth, End Depth, and Speed",
    StartDepthEndDepthAndTime: "Start Depth, End Depth, and Time",
    State: "State",
    StateCounty: "State, County",
    Status: "Status",
    SubSection: "Sub Section",
    SubmitRequest: "Submit Request",
    SubmitRequestConfirmation: "Are you sure want to submit the Request",
    Subtotal: "Subtotal",
    Summary: "Summary",
    SurfaceLatitude: "Surface Latitude",
    SurfaceLongitude: "Surface Longitude",
    SurfacePressureAreAbore70Mpa:
        "Warning surface pressure are above 68.9 MPa (10,000 psi), please discuss with operations before approving.",
    SurfacePumpingPressure: "Surface Pumping Pressure",
    SurfacePumpingPressureSPP: "Surface Pumping Pressure (SPP)",
    SurfaceWellLatLong: "Surface Well Lat/Long",
    SurfaceWellLatitude: "Surface Well Latitude",
    SurfaceWellLocation: "Surface Well Location",
    SurfaceWellLongitude: "Surface Well Longitude",
    SurveySystemType: "Survey System Type",
    SurveyType: "Survey Type",
    TVD: "TVD",
    TargetValue: "Target Value",
    Task: "Task",
    TaskAttachments: "Task Attachments",
    TaskComments: "Task Comments",
    TaskHistory: "Task History",
    TaskMustBeAssignedToAtLeastOneAssignee:
        "Task must be assigned to at least one assignee",
    TaskStatus: "Task Status",
    Tasks: "Tasks",
    TermsAndConditions: "Terms & Conditions",
    TheCombinationOfProgramAndRevisionNumbersMustBeUnique:
        "The combination of Program and Revision Numbers must be unique.",
    TheFieldXMustMatchASampleFormatOfFCGB0001MS:
        "The field {0} must match a sample format of 'FCGB0001MS'.",
    TheFieldXMustMatchTheSampleApiFormat:
        "The field {0} must match the sample API format.",
    TheFieldXMustMatchTheSampleDlsFormat:
        "The field {0} must match the sample DLS format.",
    TheFieldXMustMatchTheSampleNtsFormat:
        "The field {0} must match the sample NTS format.",
    TheFieldXMustMatchTheSamplePlssFormat:
        "The field {0} must match the sample PLSS format.",
    TheFollowingZonesStagesDoNotExist:
        "The following Zones / Stages do not exist: {0}",
    TheResourceYouRequestedCannotBeFound:
        "The Resource You Requested Cannot Be Found.",
    ThirdParty: "Third Party",
    ThisActionCanNotBePerformedBecauseOfMandatoryWarnings:
        "This action can not be performed because of warnings that can't be ignored",
    ThisActionCanNotBePerformedBecauseOfOptionalWarnings:
        "This action can not be performed because it contains warnings that are not ignored",
    ThisActionHasBeenPerformedOnThisTaskAlready:
        'User has already completed and closed this task. Click "Task" on left navigation menu to view the current status',
    ThisActionIsNotValidForTask: "Action: {0} is not valid for Task: {1}",
    ThisDocumentWasPreparedByAnAPIQ2CertifiedFacility:
        "This document was prepared by an API Q2 certified facility",
    ThisFieldAppliesToOfficialProgram:
        "This field applies to the official program approver (Eg. Account Manager, Engineer Manager).",
    ThisOperationIsNotAllowedBecauseRequestIsClosed:
        "This operation is not allowed because request has the Closed status",
    Time: "Time",
    TimeCumulative: "Time Cum",
    TimeCumulativeWithUnitSymbol: "Time Cum (hh:mm:ss)",
    TimeEndDepthAndSpeed: "Time, End Depth, and Speed",
    TimeGroup: "Time (group)",
    TimeStartDepthAndSpeed: "Time, Start Depth, and Speed",
    TimeTotal: "Time Total (hh:mm)",
    TimeUnit: "hh:mm:ss",
    Timehhmmss: "Time (hh:mm:ss)",
    ToggleNotesView: "Toggle Notes View",
    ToggleReadOnlyColumns: "Toggle Read Only Columns",
    Top: "Top",
    Total: "Total",
    TotalAmount: "Total Amount",
    TotalAndTime: "Total and Time",
    TotalBeforeDiscount: "Total Before Discount",
    TotalChemicals: "Total Chemicals",
    TotalDiscountPercentage: "Grand Total Discount (%)",
    TotalDiscountValue: "Grand Total Discount ($)",
    TotalFluids: "Total Fluids",
    TotalPerfs: "Total Perfs",
    TotalPrice: "Total Price",
    TotalPrimaryAmount: "Total Primary Amount",
    TotalSecondaryAmount: "Total Secondary Amount",
    TotalTime: "Total Time (hh:mm:ss)",
    TotalVolume: "Total Volume",
    Totals: "Totals",
    TreatmentCalculate: "Treatment Calculate",
    TreatmentMode: "Treatment Mode",
    TreatmentModeMustBeSelectedForCoilTubingRequestProgram:
        "CT Treatment Objective must be selected for Coiled Tubing and Pumping programs",
    TreatmentModes: "Treatment Modes",
    TreatmentObjective: "Treatment Objective",
    TreatmentObjectives: "Treatment Objectives",
    TreatmentTitle: "Treatment Title",
    TreatmentVolume: "Treatment Volume",
    Tubing: "Tubing",
    TubingPrefix: "Tubing:",
    TubingWithDescription: "Tubing (OD/Weight/Grade)",
    Tubings: "Tubings",
    TubularFrictionPressure: "Tubular Friction Pressure",
    TubularType: "Tubular Type",
    Tubulars: "Tubulars",
    UnCancelProgram: "Un-Cancel Program",
    UnReviseProgram: "Un-Revise Program",
    UnableToBackCalculateInvalidInputs:
        "Unable to back calculate, invalid inputs.",
    UnableToFindProgramToCopyInto: "Unable to find program to copy into",
    UnapproveProgram: "Unapprove Program",
    UnauthorizedError: "You are not authorized to use this product.",
    UnauthorizedErrorMessage:
        "Please contact Calfrac to receive valid credentials.",
    Underflush: "Underflush",
    Unit: "Unit",
    UnitPrice: "Unit Price",
    UpdateAndResetPricing: "Update and Reset Pricing",
    UpdateAndSave: "Update & Save",
    Upload: "Upload",
    UploadClusters: "Upload Clusters",
    Url: "Url",
    UsePickupDirectoryFromIis: "Use Pickup Directory From IIS",
    UseTVD: "Use TVD",
    UseTopLinerAsLinerHanger: "Top Liner",
    UsedBy: "Used By",
    UsingPricingAsOf: "Using Pricing as of (MST)",
    Uwi: "UWI",
    UwiCanada: "UWI (Canada)",
    Uwis: "UWIs (Canada)",
    ValidationMessageChemicalLoadingMustNotBeDuplicated:
        "Chemical loading must not contain duplicates. Duplicates are ",
    ValidationMessageFluidSystemAndBaseFluidMustNotBeDuplicated:
        "Fluid systems and base fluids must not be specified more than once. Duplicates are",
    ValidationMessagePropertyNameMustNotBeDuplicated:
        "{PropertyName} must not be specified more than once.",
    ValidationMessageProppantMustNotBeDuplicated:
        "Proppants must not be specified more than once.",
    ValueHasBeenOverriddenClearTheCellToResetTheValueToTheDefaultOne:
        "Value has been overridden. Clear the cell to reset the value to the default one",
    ViewDraftPdf: "Draft PDF",
    ViewFinalPdf: "Final PDF",
    ViewPdf: "View PDF",
    Volume: "Volume",
    Volume1Title: "Volume 1",
    Volume2Title: "Volume 2",
    VolumeCumulative: "Volume Cum",
    VolumeCumulative2: "Volume Cumulative",
    VolumeGroup: "Volume (group)",
    VolumeNames: "Volume Column Names",
    VolumePerPill: "Volume Per Pill",
    WallThickness: "Wall Thickness",
    Warnings: "Warnings",
    WaterSummary: "Water Summary",
    Weight: "Weight",
    Well: "Well",
    WellClass: "Well Class",
    WellClassificationIsRequiredWarning:
        "A well classification is required. Coiled Tubing info found",
    WellConfig: "Well Config",
    WellConfigCTVolume: "Well Config CT Volume",
    WellConfigCTVolumeMaxWallThickness:
        "Well Config CT Volume Max Wall Thickness",
    WellConfigCTVolumeMinWallThickness:
        "Well Config CT Volume Min Wall Thickness",
    WellConfigCasingBottom: "Well Config Casing Bottom",
    WellConfigCasingTop: "Well Config Casing Top",
    WellConfigDownHoleItem: "Well Config DownHole Item",
    WellConfigHeel: "Well Config Heel",
    WellConfigKOP: "Well Config KOP",
    WellConfigLinerBottom: "Well Config Liner Bottom",
    WellConfigLinerTop: "Well Config Liner Top",
    WellConfigOpenHoleBottom: "Well Config Open Hole Bottom",
    WellConfigOpenHoleTop: "Well Config Open Hole Top",
    WellConfigPBTD: "Well Config PBTD",
    WellConfigTubingBottom: "Well Config Tubing Bottom",
    WellConfigTubingTop: "Well Config Tubing Top",
    WellConfiguration: "Well Configuration",
    WellData: "Well Data",
    WellDescription: "Well / Zipper Group Description",
    WellIdentifier: "Well Identifier",
    WellLicense: "Well License",
    WellLocation: "Well Location",
    WellName: "Well Name",
    WellNameUsa: "Well Name (USA)",
    WellNames: "Well Names (USA)",
    WellSequence: "Well Sequence on Pad",
    WellSequenceMustBeLowerThanOrEqualToNumberOfWellsOnPad:
        "Well Sequence must be lower than or equal to the number of wells on the Pad",
    WellType: "Well Type",
    WellboreDiagram: "Wellbore Diagram",
    WellboreDiagrams: "Wellbore Diagram(s)",
    WellboreOrientation: "Wellbore Orientation",
    WrongVersion:
        "New version of JET is available ({1}). Please save your changes, then click 'Reload'.",
    XDeskPhone: "{0} (desk phone)",
    XFax: "{0} (fax)",
    XMobile: "{0} (mobile)",
    XPhone: "{0} (phone)",
    Yes: "Yes",
    YouAreNotAuthorizedToAccessThisResource:
        "You Are Not Authorized To Access This Resource.",
    YouAreNotAuthorizedToAccessThisResourceSubMessage:
        "Please contact your administrator to request access to the 'JET User' group.",
    YouHaveUnresolvedCalculationErrorsOnThisSchedule:
        "You have unresolved calculation errors that must be fixed before you can copy this schedule.",
    YouHaveUnresolvedCalculationErrorsOnThisStage:
        "You have unresolved calculation errors that must be fixed before you can copy this stage.",
    YouHaveUnsavedChanges: "JET Warning - You have unsaved changes.",
    YourUserIdIsNotListedOnTheHrTablePleaseContactSupport:
        "JET is awaiting your user ID setup in the HR System. Please contact service desk for a status update.",
    YourUserLacksAccessToTheDatabasePleaseMakeSureYouAreUsingInternetExplorer11BeforeContactingSupport:
        "Your user lacks access to the database. Please make sure you are using Google Chrome before contacting support.",
    ZipCode: "Zip Code",
    Zone: "Zone",
    ZoneStage: "Zone / Stage",
    ZoneStageNumber: "Zone / Stage #",
    ZoneStageXIsReferencedByScheduleY:
        "Zone / Stage # {0} is referenced by Schedule # {1}",
    ZoneStagesMustBeCorrectFormat:
        "Zone / Stages must be a single number or comma seperated ranges eg. 1, 4-5, 6.",
    ZoneX: "Zone {0}",
    Zones: "Zones",
    ZonesStages: "Zones / Stages",
};
