export enum Formats {
    CapacityFormat = "n5",
    CapacityFormatPattern = "{0:n5}",
    Currency = "c2",
    CurrencyFormatPattern = "{0:c2}",
    DateFormat = "MM/dd/yyyy",
    DateFormatEscaped = "MM\\/dd\\/yyyy",
    DateFormatFull = "dddd, MMMM d, yyyy",
    DateFormatFullPattern = "{0:dddd, MMMM d, yyyy}",
    DateTimeFormat = '"MM/dd/yyyy" HH:mm',
    DateTimeFormatEscaped = '"MM\\\\/dd\\\\/yyyy" h:mm tt',
    DecimalEditableDecimals = 5,
    LargeIntCommasFormat = "##,#",
    LatitudeAndLongitudeEditableDecimals = 8,
    LatitudeAndLongitudeFormat = "0.00000000",
    LatitudeAndLongitudeFormatPattern = "{0:0.00000000}",
    Percentage = "p2",
    PercentageFormatPattern = "{0:p2}",
    PermeabilityFormat = "n3",
    PricingQuantityFormat = "n1",
    Integer = "n0",
    GridInteger = "{0:n0}",
    GridOneDecimal = "{0:n1}",
    GridTwoDecimal = "{0:n2}",
    GridThreeDecimal = "{0:n3}",
    GridFourDecimal = "{0:n4}",
    GridFiveDecimal = "{0:n5}",
    GridSixDecimal = "{0:n6}",
    GridSevenDecimal = "{0:n7}",
    OneDecimal = "n1",
    TwoDecimal = "n2",
    ThreeDecimal = "n3",
    FourDecimal = "n4",
    FiveDecimal = "n5",
    SixDecimal = "n6",
    EightDecimal = "n8",
}

export enum Masks {
    ApiInputMask = "00-000-00000",
    DlsInputMask = "000/00-00-000-00W0/00",
    NtsInputMask = "000/b-A00-l/000-B-00/00",
    TimeInputMask = "00:00:00",
    TimeInputMaskHHMM = "00:00",
    DateInputMask = "00/00/0000",
}

export enum EmptyMasks {
    ApiInputMask = "__-___-_____",
    DlsInputMask = "___/__-__-___-____/__",
    NtsInputMask = "___/_-___-_/___-_-__/__",
    TimeInputMask = "__:__:__",
    TimeInputMaskHHMM = "__:__",
    DateInputMask = "__/__/____",
}

export enum ErrorMessages {
    InternalServerError = "Internal Server Error",
    NotFound = "Not Found",
    PayloadTooLarge = "Payload Too Large",
}
