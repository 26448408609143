import React, {
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { GlobalHotKeys } from "react-hotkeys";
import { toast } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { toDataSourceRequestString } from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn,
    GridItemChangeEvent,
    GridKeyDownEvent,
    GridProps,
    GridRowClickEvent,
    GridToolbar,
    UploadOnAddEvent,
} from "@progress/kendo-react-all";
import {
    getSelectedState,
    GridCellProps,
    GridSelectionChangeEvent,
} from "@progress/kendo-react-grid";
import { useQuery } from "@tanstack/react-query";
import styled, { css } from "styled-components";

import { H3 } from "components/Headers";
import DeleteRecordCell from "components/shared/CustomGridCells/DeleteRecordCell";
import StandardEditGridToolbar, {
    CustomToolbarProps,
} from "components/shared/CustomGridToolbar/StandardEditGridToolbar";
import { ValidationSummary } from "components/shared/GenericCustomComponents/StandardValidationSummary";
import { LoadingOverlay } from "components/shared/StyledComponents/LoadingOverlay";

import useDebounce from "hooks/useDebounce";

import { GridStateProvider, useGridState } from "providers/GridStateProvider";

import { fetchJET, JetApiUrls } from "utils/fetchJet";
import {
    findAndSelectFocusedCell,
    moveDownRow,
    moveToLastRow,
} from "utils/gridNavigation";

const Wrapper = styled.div<{ hiddenRows?: number[] }>`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .k-input-spinner {
        display: none;
    }

    .k-grid .k-column-title {
        white-space: normal;
    }

    ${(props) =>
        props.hiddenRows?.map(
            (idx) => css`
                & tbody tr:nth-child(${idx + 1}) {
                    //Css has to index from one
                    display: none;
                }
            `,
        )}
`;

export function setNestedObjectProperty(
    data: any,
    accessor: string,
    value: any,
): any {
    const accessors = accessor.split(".");
    let currentObj = data;

    for (let i = 0; i < accessors.length - 1; i++) {
        const currentAccessor = accessors[i];
        currentObj = currentObj[currentAccessor];
    }

    const finalAccessor = accessors[accessors.length - 1];
    currentObj[finalAccessor] = value;

    return data;
}

export type EditGridItemType = Record<"Id" | any, any>;

/**
 * StandardEditGrid
 *
 * This table is used to render static data which is not paginated.
 * This table can also be used to edit data within a form.
 *
 * @param children
 * @param path
 * @param props
 * @constructor
 */

type SharedGridProps = GridProps & {
    addRecordDefault?: EditGridItemType;
    canEdit?: boolean;
    canCreate?: boolean;
    canDeleteRecord?: boolean;
    toolbarProps?: CustomToolbarProps;
    height?: string;
    numericHeight?: number;
    showValidation?: boolean;
    // Used with react-hook-form
    name?: string;
    // Used for selectable state
    onChangeSelectedState?: (val: { [id: string]: boolean | number[] }) => void;
    initialState?: { [id: string]: boolean | number[] };
    readOnlyColumns?: Array<string | RegExp>;
    onHotKey?: (props: GridCellProps, event?: KeyboardEvent) => void;
    showAllValidations?: boolean;
    title?: string;
};

export type StaticGridType = SharedGridProps & {
    path?: undefined;
    queryParams?: undefined;
    isLoading?: boolean;
    hiddenRows?: (data: EditGridItemType[]) => number[];
    ignoreKeyPresses?: boolean;
};

type QueryGridType = SharedGridProps & {
    path: JetApiUrls;
    accessor?: (data: Record<string, unknown>) => Array<EditGridItemType>;
    queryParams?: Record<string, string>;
    params?: Record<string, string>;
};

type StandardEditGridType = StaticGridType | QueryGridType;

/**
 *
 * @param canEdit
 * @param canCreate
 * @param addRecordDefault
 * @param isLoading
 * @param children
 * @param copyRecord
 * @param canDeleteRecord
 * @param toolbarProps
 * @param name
 * @param onChangeSelectedState
 * @param dataItemKey
 * @param selectable
 * @param selectedField - If set to a column which is rendered on screen, then a checkbox will appear
 * @constructor
 */
const StaticGrid: React.FC<StaticGridType> = ({
    className = "",
    canEdit = true,
    canCreate = true,
    canDeleteRecord = true,
    showValidation = true,
    addRecordDefault,
    isLoading,
    children,
    toolbarProps,
    height = "auto",
    numericHeight,
    title = "",
    rowRender,

    // Used for react-hook-form
    hiddenRows = () => [],
    name = " ",
    onChangeSelectedState,
    dataItemKey = "Id",
    selectable,
    readOnlyColumns = [],
    data,
    initialState,
    showAllValidations = false,
    onItemChange,
    ignoreKeyPresses,
}) => {
    // FORM HOOKS
    const { append, replace } = useFieldArray({ name });
    const { setValue } = useFormContext();
    const localData: EditGridItemType[] = useWatch({ name });
    const { clearErrors } = useFormContext();
    const msal = useMsal();

    const hasTitle = title !== "";

    // Refs
    const gridRef = useRef<HTMLDivElement | null>(null);

    // SELECT GRID METHODS
    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>(initialState ?? {});
    const [entryAdded, setEntryAdded] = useState<boolean>(false);

    useEffect(() => {
        if (entryAdded) {
            // Scroll down to newly added row
            const scrollableInnerGrid = gridRef.current?.getElementsByClassName(
                "k-grid-content k-virtual-content",
            )?.[0];
            if (scrollableInnerGrid) {
                scrollableInnerGrid.scrollTop =
                    scrollableInnerGrid.scrollHeight;
            }

            // focus input into cell in the same column in the last row if user used
            // ctrl+enter hotkey from a cell elsewhere in the grid to add a record
            moveToLastRow(
                gridRef,
                hasTitle,
                localData,
                selectedState,
                setSelectedState,
            );
            setEntryAdded(false);
        }
    }, [localData?.length, entryAdded, hasTitle, localData, selectedState]);

    const onSelectionChange = (event: GridSelectionChangeEvent) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState,
            dataItemKey,
        });
        const selectedRowIds = Object.keys(newSelectedState);
        const selectedRowId = selectedRowIds[0];
        setSelectedState(newSelectedState);
        setEditID(selectedRowId);
        // Update the parent if this has been provided.
        onChangeSelectedState?.(newSelectedState);

        setTimeout(() => {
            // when user clicks on an input, highlight the content so they can replace with new value by default
            const selectedHTMLElement = document.activeElement as HTMLElement;
            if (selectedHTMLElement?.nodeName === "INPUT") {
                const inputElement = selectedHTMLElement as HTMLInputElement;
                inputElement?.select();
            }
        });
    };

    // EDIT GRID METHODS
    // Tracks how many new rows have been created to ensure we have a unique ID
    const newRowCount = useRef(0);
    const [editID, setEditID] = React.useState<number | string | null>(null);
    const rowClick = useCallback(
        (event: GridRowClickEvent) => {
            if (!canEdit) return;
            setEditID(event.dataItem.Id);
        },
        [canEdit],
    );

    const editableData = useMemo(() => {
        if (data) {
            return (data as EditGridItemType[])?.map((item) => {
                // convert both ids to strings as they can vary between
                // a number (default rows) vs string (any new records/rows added ie. "NEW-0", "NEW-1", etc)
                const itemIDString = String(item.Id);
                const editIDString = String(editID);
                return {
                    ...item,
                    inEdit: itemIDString === editIDString,
                    isSelected: selectedState[item.Id],
                };
            });
        }
        return localData?.map((item) => {
            // convert both ids to strings as they can vary between
            // a number (default rows) vs string (any new records/rows added ie. "NEW-0", "NEW-1", etc)
            const itemIDString = String(item.Id);
            const editIDString = String(editID);
            return {
                ...item,
                inEdit: itemIDString === editIDString,
                isSelected: selectedState[item.Id],
            };
        });
    }, [localData, editID, selectedState, data]);

    const itemChange = useCallback(
        (event: GridItemChangeEvent) => {
            if (!canEdit) return;
            const inEditID = event.dataItem.Id;

            // Fields can be single values like "Name"
            // or nested properties like "Activator.Id"
            const field = event.field || "";

            // determine if the field is a nested field
            const nestedFieldData = localData?.find(
                (item) => item.Id === inEditID,
            );
            if (!nestedFieldData) return;

            const newRow = setNestedObjectProperty(
                nestedFieldData,
                field,
                event.value,
            );
            const newData = localData?.map((item) =>
                item.Id === inEditID ? { ...item, ...newRow } : item,
            );
            replace(newData);

            onItemChange?.(event);
        },
        [canEdit, onItemChange, localData, replace],
    );

    const closeEdit = useCallback(
        (event: SyntheticEvent) => {
            if (event.target === event.currentTarget) {
                setEditID(null);
            }
            clearErrors(name);
        },
        [clearErrors, name],
    );

    const addRecord = useCallback(() => {
        if (!canCreate) return;

        // Random String of letters and numbers to be used as a new record key.
        let key = Math.random().toString(36).substring(2, 15);
        const nextId = `NEW-${key}`;

        const newRecord = { ...addRecordDefault, Id: nextId };
        setEditID(newRecord.Id);
        append(newRecord);
        clearErrors(name);
        setEntryAdded(true);
    }, [addRecordDefault, append, canCreate, clearErrors, name]);

    const addFile = useCallback(
        (fileValue: UploadOnAddEvent) => {
            fileValue.newState.forEach((file) => {
                if (file.getRawFile) {
                    const nextId = `NEW-${newRowCount.current++}`;

                    // Replace code for character
                    const pattern = "&#39;"; // &#39 = '
                    const regex = new RegExp(pattern, "g");

                    const newRecord = {
                        EnteredBy: msal.accounts[0].name,
                        EnteredOn: new Date().toISOString(),
                        CreatedBy: msal.accounts[0].name,
                        Date: new Date().toISOString(),
                        FileName: file.name.replace(regex, "'"),
                        NewAttachmentFileGuid: file.uid,
                        Id: nextId,
                    };

                    append(newRecord);
                } else {
                    toast.error(
                        `Error uploading file: ${file.name}. Please try again.`,
                        {
                            autoClose: false,
                        },
                    );
                }
            });

            // TODO: Tech debt. Required to clear errors when deleting a record.
            clearErrors();
        },
        [append, clearErrors, msal.accounts],
    );

    const updateData = useCallback(
        (newData: EditGridItemType[]) => {
            const previousCreatedNewRows = (
                editableData as EditGridItemType[]
            ).filter(
                (row) => typeof row.Id === "string" && row.Id.includes("NEW-"),
            );

            let newRecordCount = previousCreatedNewRows.length;

            const newRecords = newData.map((item) => {
                const nextId = `NEW-${newRecordCount++}`;
                return { ...item, Id: nextId };
            });
            setValue(name, newRecords ?? [], { shouldDirty: true });
        },
        [editableData, name, setValue],
    );

    // OTHER METHODS
    const handleKeyDown = useCallback(
        (event: GridKeyDownEvent) => {
            if (ignoreKeyPresses) return;

            const keyboardEvent = event.nativeEvent as KeyboardEvent;
            switch (keyboardEvent.key) {
                case "Enter":
                    keyboardEvent.preventDefault();

                    // CMD+Enter and CTRL+Enter (CREATE hotkeys), add a new record to the grid
                    if (keyboardEvent.metaKey || keyboardEvent.ctrlKey) {
                        addRecord();
                    } else if (editableData !== undefined) {
                        moveDownRow(
                            editableData,
                            selectedState,
                            setSelectedState,
                            event,
                            setEditID,
                        );
                    }
                    break;
                case "Tab":
                    // have to wait for tab to complete and move to next element before updating our states
                    setTimeout(() => {
                        if (editableData !== undefined) {
                            findAndSelectFocusedCell(
                                editableData,
                                setSelectedState,
                                setEditID,
                            );
                        }
                    });
                    break;
                case "s":
                    // CMD+S and CTRL+S (SAVE hotkeys), submit form from inside grid
                    if (keyboardEvent.metaKey || keyboardEvent.ctrlKey) {
                        keyboardEvent.preventDefault(); // stop browser from trying to save webpage to user files
                        const focusedElement = document.activeElement;
                        if (focusedElement?.nodeName === "INPUT") {
                            const focusedInput =
                                focusedElement as HTMLInputElement;
                            setEditID(null); // reset grid to view mode
                            clearErrors(); // clear form errors so form submission can be fired again
                            focusedInput.blur(); // ensure cell value has been saved to form
                        }
                    }
                    break;
                case "Escape":
                    setEditID(null);
                    break;
            }
        },
        [
            selectedState,
            setSelectedState,
            setEditID,
            addRecord,
            clearErrors,
            editableData,
            ignoreKeyPresses,
        ],
    );

    /**
     * When the user clicks outside of the grid or on a cell in another grid on the page,
     * move the previous grid into view mode by setting editID to null.
     * If this isn't done, focus gets left with the previous editable cell when the user
     * starts typing somewhere else in the page.
     */
    const handleGridBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
        const currentTarget = e.currentTarget; // the current grid container

        // Check the newly focused element in the next tick of the event loop
        setTimeout(() => {
            const activeElement = document.activeElement;
            // Get the expanded dropdown element if one was clicked on the page.
            // The activeElement when a dropdown list is expanded is actually a descendant of the container that is opened,
            // not the original element that was clicked to expand the dropdown list.
            // As a result, we cannot track if the document activeElement is a child of the current grid, and must verify
            // if the element that opened that dropdown list is not a child of the current grid container either.
            const activeDropdowns = document.querySelectorAll(
                'span[aria-expanded="true"]',
            );
            const activeDropdown =
                activeDropdowns && activeDropdowns.length !== 0
                    ? activeDropdowns[0]
                    : null;

            // If the new activeElement and the new active dropdown are not children of the original grid,
            // the user has clicked outside of the grid on the page, and we set this grid back to view mode.
            if (
                !currentTarget.contains(activeElement) &&
                !currentTarget.contains(activeDropdown)
            ) {
                setEditID(null);
            }
        }, 0);
    };

    return (
        <Wrapper
            className={`col-span-full ${className} h-full`}
            hiddenRows={hiddenRows?.(editableData)}
        >
            <GridStateProvider
                isGridEditable={canEdit}
                formName={name}
                readOnlyColumns={readOnlyColumns}
                rowCount={editableData?.length}
            >
                {showValidation && (
                    <ValidationSummary
                        name={showAllValidations ? undefined : name}
                    />
                )}
                <GlobalHotKeys
                    handlers={{
                        CREATE: (e) => {
                            e?.preventDefault();
                            addRecord();
                        },
                    }}
                >
                    <div
                        className={"-mx-4 -my-4 h-full rounded-xl"}
                        tabIndex={1}
                        onBlur={handleGridBlur}
                        ref={gridRef}
                    >
                        {hasTitle && (
                            <div className="px-4 pb-4">
                                <H3>{title}</H3>
                            </div>
                        )}
                        <GridWithContext
                            canEdit={canEdit}
                            canCreate={canCreate}
                            canDeleteRecord={canDeleteRecord}
                            children={children}
                            toolbarProps={toolbarProps}
                            height={height}
                            numericHeight={numericHeight}
                            rowRender={rowRender}
                            selectable={selectable}
                            onSelectionChange={onSelectionChange}
                            editableData={editableData}
                            itemChange={itemChange}
                            handleKeyDown={handleKeyDown}
                            addRecord={addRecord}
                            entryAdded={false}
                            updateData={updateData}
                            addFile={addFile}
                            closeEdit={closeEdit}
                            rowClick={rowClick}
                            size={"small"}
                        />
                    </div>
                </GlobalHotKeys>
                {isLoading && <LoadingOverlay />}
            </GridStateProvider>
        </Wrapper>
    );
};

type GridType = {
    rowClick: (event: GridRowClickEvent) => void;
    onSelectionChange: (event: GridSelectionChangeEvent) => void;
    editableData: any;
    itemChange: (event: GridItemChangeEvent) => void;
    handleKeyDown: (event: GridKeyDownEvent) => void;
    addRecord: () => void;
    entryAdded: boolean;
    updateData: (newData: EditGridItemType[]) => void;
    addFile: (fileValue: UploadOnAddEvent) => void;
    closeEdit: (event: SyntheticEvent) => void;
};

const GridWithContext = ({
    canEdit = true,
    canCreate = true,
    canDeleteRecord = true,
    children,
    toolbarProps,
    height = "auto",
    numericHeight,
    rowRender,
    selectable,
    rowClick,
    onSelectionChange,
    editableData,
    itemChange,
    handleKeyDown,
    addRecord,
    entryAdded,
    updateData,
    addFile,
    closeEdit,
}: StaticGridType & GridType) => {
    const { ignoreKeyPresses } = useGridState();

    return (
        <Grid
            data={editableData}
            editField={canEdit ? "inEdit" : undefined}
            selectedField={selectable?.enabled ? "isSelected" : undefined}
            onRowClick={rowClick}
            onItemChange={itemChange}
            onKeyDown={(e) => {
                if (!ignoreKeyPresses) handleKeyDown(e);
            }}
            onSelectionChange={onSelectionChange}
            selectable={selectable}
            navigatable={true}
            style={{
                height: numericHeight
                    ? `${Math.min(editableData?.length * 83, numericHeight)}px`
                    : height,
            }}
            dataItemKey={"Id"}
            resizable
            rowRender={rowRender}
        >
            {(toolbarProps || (canEdit && canCreate)) && (
                <GridToolbar className={"overflow-hidden"}>
                    <GridToolbarContainer onClick={closeEdit}>
                        <StandardEditGridToolbar
                            {...toolbarProps}
                            addNewProps={{
                                ...toolbarProps?.addNewProps,
                                isVisible:
                                    (toolbarProps?.addNewProps?.isVisible ??
                                        true) &&
                                    canEdit &&
                                    canCreate,
                                onClick: addRecord,
                                readOnly: entryAdded,
                            }}
                            loadDefaultsProps={{
                                ...toolbarProps?.loadDefaultsProps,
                                onNewData: updateData,
                            }}
                            addNewFileProps={{
                                ...toolbarProps?.addNewFileProps,
                                onClick: addFile,
                            }}
                        />
                    </GridToolbarContainer>
                </GridToolbar>
            )}
            <GridColumn
                cell={DeleteRecordCell}
                width={canDeleteRecord && canEdit ? 52 : 0}
                resizable={false}
                locked
            />

            {children}
        </Grid>
    );
};

const GridToolbarContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: 15px;
    width: 100%;

    & > button {
        margin: 0;
    }
`;

const QueryGrid: React.FC<QueryGridType> = ({
    children,
    path,
    queryParams,
    accessor,
    ...props
}) => {
    const [localData, setLocalData] = React.useState<Array<EditGridItemType>>(
        [],
    );

    const queryParamsString = useDebounce(
        toDataSourceRequestString(queryParams || {}),
    );

    // Fetch the remote data
    const remoteData = useQuery<EditGridItemType[]>({
        queryFn: async () => {
            const data = await fetchJET<Record<string, unknown>>(
                path,
                {},
                queryParamsString,
            );

            if (accessor) return accessor(data);

            return data as unknown as Array<EditGridItemType>;
        },
        queryKey: [path, queryParamsString],
    });

    // sync local and remote data
    // TODO: this will overwrite any local changes
    useEffect(() => {
        setLocalData(remoteData.data || []);
    }, [remoteData.data]);

    return (
        <StaticGrid
            path={undefined}
            {...props}
            data={localData}
            isLoading={remoteData.isFetching}
        >
            {children}
        </StaticGrid>
    );
};

/**
 * StandardEditGrid
 *
 * Provide a path and this will query for data.
 * Provide data and this will render statically.
 *
 * @param props
 * @constructor
 */
const StandardEditGrid: React.FC<StandardEditGridType> = (props) => {
    if (props.path) {
        return <QueryGrid {...props} />;
    } else {
        return <StaticGrid {...props} />;
    }
};

export default StandardEditGrid;
