import React, { useCallback, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { UploadOnAddEvent } from "@progress/kendo-react-all";

import StandardFileUpload from "components/shared/GenericCustomComponents/StandardFileUpload";
import { StyledDialog } from "components/shared/StyledComponents/SharedStyledComponents";

import { resources } from "utils/resources";

import AddNew from "./AddNew";

export interface AddFileProps {
    label?: string;
    allowMultiple?: boolean;
    showFileList?: boolean;
    onClick?: (e: UploadOnAddEvent) => void;
    isVisible?: boolean;
    allowedExtensions?: string[];
}

const AddFile: React.FC<AddFileProps> = ({
    label = resources.AddAttachment,
    isVisible = true,
    allowedExtensions,
    ...props
}: AddFileProps) => {
    const { setValue } = useFormContext();
    const value = useWatch({ name: "files" });
    // State
    const [visible, setVisible] = useState<boolean>(false);

    const handleOpenClose = useCallback(() => {
        setVisible(!visible);
    }, [visible]);

    const handleUpload = useCallback(
        async (e: UploadOnAddEvent) => {
            props.onClick?.(e);
            setVisible(false);

            const nextFiles: File[] = [];

            // Safely add the files which are not undefined to the list above.
            for (let i = 0; i < e?.newState?.length; i++) {
                const file = e.newState?.[i]?.getRawFile?.();
                if (!file) {
                    toast.error(resources.InvalidFile, {
                        autoClose: false,
                    });
                    continue;
                }
                nextFiles.push(file);
            }

            setValue("files", value ? [...value, ...nextFiles] : nextFiles);
        },
        [props, setValue, value],
    );

    if (!isVisible) return <></>;

    return (
        <>
            {visible && (
                <StyledDialog
                    onClose={handleOpenClose}
                    title={label + " (max 1GB)"}
                    width={400}
                    height={125}
                >
                    <StandardFileUpload
                        allowMultiple={true}
                        showFileList={true}
                        allowedExtensions={allowedExtensions}
                        onAdd={handleUpload}
                    />
                </StyledDialog>
            )}
            <AddNew label={label} onClick={handleOpenClose} />
        </>
    );
};

export default AddFile;
